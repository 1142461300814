var _process3, _process3$env;
var __jsx = React.createElement;
import '../themes/flexboxgrid.css';
import '../themes/global.css';
import React from 'react';
import GlobalStateProvider from '../global-state-context';
import withLinkValidation from '../utils/withLinkValidation';
import { NinetailedProvider } from '@ninetailed/experience.js-next';
import { NinetailedInsightsPlugin } from '@ninetailed/experience.js-plugin-insights';
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";
import '@contentful/live-preview/style.css';
function MyApp(_ref) {
  var _process, _process$env, _process2, _process2$env;
  var Component = _ref.Component,
    pageProps = _ref.pageProps;
  return __jsx(ContentfulLivePreviewProvider, {
    locale: "en-US",
    enableInspectorMode: ((_process = process) === null || _process === void 0 ? void 0 : (_process$env = _process.env) === null || _process$env === void 0 ? void 0 : _process$env.NEXT_PUBLIC_ENABLE_PREVIEW) === 'true',
    enableLiveUpdates: ((_process2 = process) === null || _process2 === void 0 ? void 0 : (_process2$env = _process2.env) === null || _process2$env === void 0 ? void 0 : _process2$env.NEXT_PUBLIC_ENABLE_PREVIEW) === 'true'
  }, __jsx(NinetailedProvider, {
    clientId: "0f6ff73a-c172-4c35-b11b-c8a0a2564929",
    environment: "development",
    plugins: [new NinetailedInsightsPlugin()],
    componentViewTrackingThreshold: 2000,
    requestTimeout: 5000,
    locale: "co",
    url: "https://experience.ninetailed.co",
    useSDKEvaluation: true
  }, __jsx(GlobalStateProvider, {
    initialData: pageProps
  }, __jsx(Component, pageProps))));
}
export default ((_process3 = process) === null || _process3 === void 0 ? void 0 : (_process3$env = _process3.env) === null || _process3$env === void 0 ? void 0 : _process3$env.NEXT_PUBLIC_ENABLE_PREVIEW) === 'true' ? withLinkValidation(MyApp) : MyApp;